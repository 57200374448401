@use '@/styles/utils/mixins.scss' as *;

.galleryWrapper {
  margin: 64px 0;
  :global {
    .image-gallery-content {
      display: flex;
      gap: 16px;
      @media screen and (max-width: $maxTablet) {
        flex-direction: column;
      }
    }

    .image-gallery-slide-wrapper {
      @media screen and (max-width: $maxTablet) {
        width: 100%;
      }
    }

    .image-gallery-thumbnails-wrapper {
      width: 246px;
      @media screen and (max-width: $maxTablet) {
        width: 100%;
      }
    }

    .image-gallery-thumbnails {
      padding: 0;

      .image-gallery-thumbnails-container {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: $maxTablet) {
          display: block;
        }

        .image-gallery-thumbnail {
          width: 100%;
          margin: 0 15px 15px 0;
          border: none;
          @media screen and (max-width: $maxTablet) {
            width: 143px;
          }
          img {
            height: 127px;
            object-fit: cover;
            @media screen and (max-width: $maxTablet) {
              height: 80px;
            }
          }
        }

        .image-gallery-thumbnail:first-of-type {
          display: none;
        }
      }
    }

    .image-gallery-slides {
      img {
        height: 410px;
        object-fit: cover;
        @media screen and (max-width: $minTablet) {
          height: 240px;
        }
      }
    }

    .image-gallery-play-button {
      display: none;
    }

    .image-gallery-fullscreen-button {
      width: 100%;
      height: 100%;

      svg {
        display: none;
      }
    }

    .fullscreen-button {
      width: max-content;
      height: auto;
      position: absolute;
      top: -50px;
      left: -240px;
      display: flex;
      align-items: center;
      color: $kiaMidnightBlack;
      filter: none;
      font-size: 14px;
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      padding: 0;
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
      @media screen and (max-width: $minTablet) {
        left: 24px;
        top: -160px;
      }
    }

    .arrow-left,
    .arrow-right {
      position: absolute;
      z-index: 5;
      @media screen and (max-width: $maxTablet) {
        display: none;
      }
    }

    .fullscreen {
      .arrow-left {
        position: absolute;
        left: 1%;
        top: 50%;
        rotate: 0deg;
      }

      .arrow-right {
        position: absolute;
        left: 96%;
        top: 50%;
        rotate: 0deg;
      }
    }

    .arrow-left {
      left: -150px;
      top: -24px;
      rotate: 90deg;
    }

    .arrow-right {
      left: -150px;
      bottom: -24px;
      rotate: 90deg;
    }

    .image-gallery-content.fullscreen {
      .image-gallery-thumbnails-wrapper {
        display: none;
      }

      .image-gallery-slide-wrapper {
        width: 100vw;
        margin: 0;
      }

      .image-gallery-slides {
        img {
          height: 100vh;
          max-height: unset;
        }
      }

      .image-gallery-fullscreen-button {
        width: auto;
        height: auto;

        svg {
          display: block;
        }
      }

      .image-gallery-icon:hover {
        color: #000000;
      }
    }
  }
  .galleryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .galleryMainTitle {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.2;
      letter-spacing: -0.6px;
      text-transform: uppercase;
      color: $kiaMidnightBlack;
    }

    .galleryTabs {
      display: flex;

      .galleryTab {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.29;
        color: #8a8b8a;
        background-color: #f8f8f8;
        border-radius: 100px;
        border: 1px solid #dddddd;
        cursor: pointer;
      }

      .galleryTab.active {
        background-color: $kiaMidnightBlack !important;
        color: #ffffff !important;
        z-index: 2;
      }

      .galleryInterior {
        padding: 10px 65px 10px 65px;
      }

      .galleryExterior {
        padding: 10px 47px 10px 65px;
      }

      .galleryExterior.active {
        margin-left: -30px;
      }

      .galleryInterior.active {
        margin-right: -30px;
      }
    }
  }
  .buttonLeft,
  .buttonRight {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $kiaMidnightBlack;
    width: 48px;
    height: 48px;
    border: 1px solid $kiaPolarWhite;
    border-radius: 50%;
    z-index: $sliderArrowsZIndex;
    transition: all 0.1s ease-out;
    font-weight: bold;
    cursor: pointer;

    &:before {
      content: '';
    }

    &:hover {
      background-color: black;
    }
  }

  .buttonRight {
    transform: rotate(180deg);
  }

  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
        @include mob() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 20px;
        line-height: 1;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }

    @media screen and (max-width: $minTablet) {
      padding: 0 24px;
    }
  }

  @media screen and (max-width: $maxTablet) {
    margin: 40px 0;

    .galleryHeader {
      display: block;
      margin-bottom: 25px;
      padding: 0 22px;

      .galleryMainTitle {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.13;
        letter-spacing: -0.16px;
      }

      .galleryTabs {
        margin-top: 16px;

        .galleryTab {
          font-size: 12px;
          line-height: 1.5;
        }

        .galleryInterior {
          padding: 10px 65px 10px 58px;
        }

        .galleryExterior {
          padding: 10px 58px 10px 65px;
        }
      }
    }

    .gallerySlider {
      margin-bottom: 50px;
    }

    .gallerySliderItem {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .image-gallery-fullscreen-button {
        width: auto;
        height: auto;

        svg {
          display: block;
        }
      }
    }

    :global {
      .slick-dots {
        display: flex !important;
        justify-content: center;

        li {
          width: 15px;

          button {
            width: 15px;
          }

          button:before {
            width: 15px;
          }
        }
      }
    }
  }
  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }
}
